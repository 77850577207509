html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button:focus{ border:0;outline:0}

html, body {
  overflow: auto !important;
}

code {
  font-family: "Roboto";
}

a, a:hover {
  color: inherit;
  text-decoration: none;
}

a.link {
  color: #2faab1;
}

.Content {
  width: 100%;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 3em;
  max-height: 90vh;
  overflow-y: auto;
}

input {
  border-radius: 30px;
}

@keyframes fadeIn {
  from { opacity: 0 };
  to { opacity: 1 };
}

 

.fadeIn {
  animation: fadeIn 800ms ease-in-out;
}

/* Mimik Dots Spinner */
.mimik-dots-spinner {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.mimik-dots-spinner div {
  animation: mimik-dots-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}
.mimik-dots-spinner div:after {
  content: '';
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px;
}
.mimik-dots-spinner div:nth-child(1) {
  animation-delay: -0.036s;
}
.mimik-dots-spinner div:nth-child(1):after {
  top: 50px;
  left: 50px;
  background: #F39200;
}
.mimik-dots-spinner div:nth-child(2) {
  animation-delay: -0.072s;
}
.mimik-dots-spinner div:nth-child(2):after {
  top: 54px;
  left: 45px;
  background: #E6332A;
}
.mimik-dots-spinner div:nth-child(3) {
  animation-delay: -0.108s;
}
.mimik-dots-spinner div:nth-child(3):after {
  top: 57px;
  left: 39px;
  background: #FFD100;
}
.mimik-dots-spinner div:nth-child(4) {
  animation-delay: -0.144s;
}
.mimik-dots-spinner div:nth-child(4):after {
  top: 58px;
  left: 32px;
  background: #E6332A;
}
.mimik-dots-spinner div:nth-child(5) {
  animation-delay: -0.18s;
}
.mimik-dots-spinner div:nth-child(5):after {
  top: 57px;
  left: 25px;
  background: #40ACB6;
}
@keyframes mimik-dots-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Mimik Dot Pulse */
.mimik-dot-pulse {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 100px auto;
}

.mimik-dot-pulse > div {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #FFD100;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: dot-pulse 2.0s infinite ease-in-out;
  animation: dot-pulse 2.0s infinite ease-in-out;
}

.mimik-dot-pulse > div:nth-child(2) {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}


@-webkit-keyframes dot-pulse {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes dot-pulse {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

/* Mimik Loader */
.mimik-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.mimik-loader div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: transparent;
  animation: mimik-loader 2.4s infinite;
}
.mimik-loader div:nth-child(1) {
  background: #F39200;
}
.mimik-loader div:nth-child(2) {
  background: #E6332A;
  animation-delay: -0.6s;
}
.mimik-loader div:nth-child(3) {
  background: #FFD100;
  animation-delay: -1.2s;
}
.mimik-loader div:nth-child(4) {
  background: #40ACB6;
  animation-delay: -1.8s;
}
@keyframes mimik-loader {
  0% { transform: scale(0); left: 8px; z-index: 1; }
  25% { transform: scale(1); left: 8px; z-index: 2; }
  50% { transform: scale(1); left: 32px; z-index: 2; }
  75% { transform: scale(1);  left: 56px; z-index: 2; }
  100% { transform: scale(0); left: 56px; z-index: 1; }
}

/* Deprecated Ribbons */

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: red;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 500 1em 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

ul.dot_signup{position: relative;}
ul.dot_signup::before{
  content: '';
  background-color: #3EA8B2;
  width: 2px;
  position: absolute;
  top: 22px;
  left: 4px;
  bottom: 22px;
}
ul.dot_signup li p{padding-left: 30px;}
ul.dot_signup li::before{
  content: '';
  position: absolute;
  left: 0;
  top: 18px;
  width: 11px;
  height: 11px;
  background-color: #3EA8B2;
  border-radius: 11px;
}

ul.dot_login{position: relative;}
ul.dot_login::before{
  content: '';
  background-color: #3EA8B2;
  width: 2px;
  position: absolute;
  top: 22px;
  left: 4px;
  bottom: 37px;
}
ul.dot_login li p{padding-left: 30px;}
ul.dot_login li::before{
  content: '';
  position: absolute;
  left: 0;
  top: 18px;
  width: 11px;
  height: 11px;
  background-color: #3EA8B2;
  border-radius: 11px;
}